<template>
  <div class="text-center">
    <v-dialog 
      v-model="dialog" 
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          :small="true"
        >
          <v-icon 
            v-if="showIcon" 
            left
          > 
            mdi-login
          </v-icon>
          <div class="hidden-md-only"> {{ buttonText  }} </div>
        </v-btn>
      </template>

      <v-card 
        class="mx-auto"
        max-width="400"
        color="primary"
      >
        <v-card-title
          class="text-center title font-weight-regular"
        >
        <v-img
          class="mb-2"
          contain
          height="180"
          :src="require('@/assets/logo-header.png')"
        ></v-img>
        <!-- <span>Log In</span> -->
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="Username"
            v-model="username"
            outlined
            solo
            clearable
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>

        <v-card-text class="text-center">
          <v-text-field
            v-model="password"
            :type="showpass ? 'text' : 'password'"
            label="Password"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            outlined
            solo
            clearable
            :rules="[rules.required]"
            @click:append="showpass = !showpass"
          />
        </v-card-text>
        <v-card-text
          class="text-center"
        >
          <v-row>
            <v-col class="mb-0 pb-0">
              <v-btn
                text
                color="white"
                :small="true"
                @click="openReset"
              >           
                <div class="hidden-md-only">Olvidé mi contraseña</div> 
              </v-btn>
            </v-col>
            <v-col v-if="!showIcon" class="mt-0 pt-0">
              <signup-card
                buttonText="No tengo cuenta"
                :showIcon="false"
                @closeLogin="dialog = false" />
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-progress-circular
          indeterminate
          color="amber"
          v-if="status === 'loading'"
          :size="50"
          :width="5"
        />
        <v-alert
          class="mr-4 ml-4"
          v-if="status === 'error'"
          dense
          type="error"
        >
        <strong>Usuario</strong> o <strong>Contraseña</strong> incorrectos!
        </v-alert>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            :disabled="invalidFields"
            @click="loginUser"
            depressed
          >
            Entrar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
import { mapActions, mapState } from 'vuex'
import SignupCard from './SignupCard.vue'
import { APIUrl } from '@/api_utils/axios-base'

  export default {
  components: { SignupCard },
    props: {
      buttonText: {
        type: String,
        default: "Ingresar"
      },
      showIcon: {
        type: Boolean,
        default: true
      },
    },
    mixins: [validationMixin],
    data: () => ({
      step: 1,
      dialog: false,
      username: '',
      password: '',
      token: localStorage.getItem('token') || null,
      showpass: false,
      loader: false,
      resetPage: `${APIUrl}/password_reset`
    }),
    computed: {
      ...mapState('usuario', ['status']),
      invalidFields () {
        if(this.rules.required(this.username) === 'Requerido' || this.rules.required(this.password) === 'Requerido')
        {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      ...mapActions('usuario', ['login', 'validateStripeDistributorAccount']),
      async loginUser() {
        let userData = {
          username: this.username,
          password: this.password,
        }
        await this.login(userData)
        await this.validateStripeDistributorAccount()
      },
      openReset() {
        window.open(this.resetPage, '_blank')
      }
    },
  }
</script>

<style scoped>
a {
  text-transform: uppercase;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>