<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          :color="showIcon ? 'primary' : 'white'"
          :small="true"
        >
          <v-icon 
            v-if="showIcon"
            left
          > 
            mdi-account-plus-outline 
          </v-icon>
            <div class="hidden-md-only">{{ buttonText }}</div> 
        </v-btn>
      </template>

      <v-card class="mx-auto" max-width="500">
        <v-card-title
          class="title font-weight-regular justify-space-between"
        >
          <span>{{ currentTitle }}</span>
          <v-avatar
            color="primary"
            class="subheading white--text"
            size="24"
            v-text="step"
          ></v-avatar>
        </v-card-title>

        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[rules.required, rules.email, emailAvailabilityRule]"
                outlined
                clearable
              ></v-text-field>
              <span class="caption grey--text text--darken-1">
                Este es el email que usarás para iniciar sesión en Tyasa. 
              </span>
            </v-card-text>
          </v-window-item>
 
          <v-window-item :value="2">
            <v-card-text>
              <v-text-field
                v-model="username"
                label="Username"
                :rules="[rules.required, userAvailabilityRule]"
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                v-model="name"
                label="Nombre"
                :rules="[rules.required]"
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                v-model="lastName"
                label="Apellido Paterno"
                :rules="[rules.required]"
                outlined
                clearable
              ></v-text-field>
              <v-text-field
                v-model="mLastName"
                label="Apellido Materno"
                :rules="[rules.required]"
                outlined
                clearable
              ></v-text-field>
              <v-file-input
                v-model="picture"
                label="Foto de Perfil"
                filled
                clearable
                prepend-icon="mdi-camera"
                accept="image/png, image/jpeg, image/bmp"
                :rules="[rules.profileImgSize]"
              >
              </v-file-input>
              <span class="caption grey--text text--darken-1">
                Esta información estará asociada a tu cuenta.
              </span>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="3">
            <v-card-text>
              <v-text-field
                v-model="pass"
                label="Password"
                :type="showpass ? 'text' : 'password'"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.passMinLength]"
                @click:append="showpass = !showpass"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="confirmPass"
                label="Confirm Password"
                :type="showConfirmPass ? 'text' : 'password'"
                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[passwordConfirmationRule]"
                @click:append="showConfirmPass = !showConfirmPass"
                outlined
              ></v-text-field>
              <span class="caption grey--text text--darken-1">
                Por favor introduce una contraseña para tu cuenta.
              </span>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="4">
            <div class="pa-4 text-center">
              <div v-if="loading">
                <component-loader />
              </div>
              <div v-else>
                <v-img
                  class="mb-4"
                  contain
                  height="128"
                  :src="require('@/assets/logo-header.png')"
                ></v-img>
                <h3 class="title font-weight-light mb-2">Bienvenido a Construfácil</h3>
                <span class="caption grey--text">Gracias por registrarte!</span>
              </div>
            </div>
          </v-window-item>
          <!-- <v-window-item :value="5"> -->
          <!--   <div> -->
          <!--     <label>Card Holder Name</label> -->
          <!--     <input type="text" id="card-holder-name" v-model="nameCard"> -->

          <!--     <label>Card:</label> -->
          <!--     <div id="card-element"></div> -->
                
          <!--     <button class="my-4 mx-4" @click="submitPaymentMethod">submitPaymentMethod</button> -->
          <!--   </div> -->
          <!-- </v-window-item> -->
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn :disabled="step === 1" text @click="step--"> Back </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="invalidFields"
            color="primary"
            depressed
            @click="step++"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { validationMixin } from '@/mixins/formsValidations.js'
  import { postStripe } from '@/api_utils/axios-base'
  import { axiosBase } from '@/api_utils/axios-base'
  import { mapActions } from 'vuex'
  import componentLoader from '@/components/ComponentLoader'

  export default {
    props: {
      buttonText: {
        type: String,
        default: 'Regístrate'
      },
      showIcon: {
        type: Boolean,
        default: true
      },
    },
    components: {
      componentLoader,
    },
    mixins: [validationMixin],
    data: () => ({
      step: 1,
      dialog: false,
      showpass: false,
      showConfirmPass: false,
      email: '',
      pass: '',
      confirmPass: '',
      username: '',
      name: '',
      lastName: '',
      mLastName: '',
      picture: null,
      stripe: null,
      card: '',
      intentToken: '',
      nameCard: '',
      availability: null,
      duplicityError: null,
      loading: false,
    }),
    watch: {
      dialog(val) {
        if (val) {
          this.$emit('closeLogin')
        }
      }
    },
    computed: {
      currentTitle () {
        switch (this.step) {
          case 1: return 'Regístrate'
          case 2: return 'Datos Personales'
          case 3: return 'Contraseña'
          default: return this.loading ? 'Creando cuenta' : 'Cuenta Creada!'
        }
      },
      passwordConfirmationRule() {
        return () => (this.pass === this.confirmPass) || 'Contraseñas no coinciden'
      },
      emailAvailabilityRule() {
        if (this.rules.email(this.email) !== "e-mail inválido") {
          axiosBase.post('/personas/validar-email', {email: this.email})
            .then(resp => {
                this.availability = resp.data.disponibilidad
            })
        }
        return this.availability || "Correo no disponible"
      },
      userAvailabilityRule() {
        return !this.duplicityError || "Usuario no disponible"
      },
      invalidFields () {
        /*
          *TODO: Agregar validación para la foto de perfil y como guardar ese campo.
          *El segundo campo de password se queda en rojo con la validación.
        */
        if(this.step === 1 
          && (this.rules.email(this.email) === 'e-mail inválido' ||
           this.emailAvailabilityRule === 'Correo no disponible')
        ) {
          return true 
        } else if (
                    this.step === 2 &&
                    (
                      this.rules.required(this.username) === 'Requerido' ||
                      this.rules.required(this.name) === 'Requerido' ||
                      this.rules.required(this.lastName) === 'Requerido' ||
                      this.rules.required(this.mLastName) === 'Requerido'
                    )
                  )
        {
          return true
        } else if (
                    this.step === 3 &&
                    (
                      this.passwordConfirmationRule(this.pass, this.confirmPass) === 'Contraseñas no coinciden' ||
                      this.rules.passMinLength(this.pass) === 'Mínimo 5 caracteres'
                    )
                  )
        {
          return true 
        } else if(this.step === 4) {
          this.loading = true
          let cliente = {
            correo: this.email,
            nombreUsuario: this.username,
            nombre: this.name,
            apellidoPaterno: this.lastName,
            apellidoMaterno: this.mLastName,
            fotoPerfil: this.picture,
            password: this.pass,
            stripeId: ""
          }
          
          //postStripe({
          //  url: '/customers',
          //  params: {
          //    email: this.email,
          //    name: this.username
          //  },
          //})
          //  .then(({ data })=> {
          //    cliente.stripeId = 1//data.id
          //    console.log("cliente creado:", cliente)
            //  postStripe({
            //    url: '/setup_intents',
            //    params: {
            //      customer: data.id
            //    }
            //  })
            //    .then( resp => {
            //      console.log("finalizo setupIntent", resp)
            //      return true
            //    })
              axiosBase.post('/personas/crear-cliente-pb', cliente)
                .then(resp => {
                  // console.log("respuesta creacion cliente", resp)
                  if (resp.data.error) {
                    // console.log("Ocurrio un error al intentar crear cliente");
                    this.duplicityError = 
                      resp.data.e.find(er => er === "UNIQUE constraint failed: land_user.username")
                    if (this.duplicityError) {
                      this.loading = false
                      this.step = 2
                      setTimeout(() => {
                        this.duplicityError = null
                      }, 1200)
                    }
                  } else {
                    this.loading = false
                    setTimeout(() => {
                      this.dialog = false
                      this.loginUser()
                    }, 1000)
                  }
                })
           // })
        //} else if(this.step === 5){
        //  let elements = this.stripe.elements()
        //  this.card = elements.create('card')
        //  setTimeout(() => {
        //    this.card.mount('#card-element')
        //  }, 2000)
            
        } else {
          return false
        }
      }
    },
    methods: {
      ...mapActions('usuario', ['login']),
      loginUser() {
        let data = {
          username: this.username,
          password: this.pass
        }
        this.login(data)
      },
      //initStripe() {
      //  this.stripe = Stripe('pk_test_51I78FGD6fjWGaVAKKNo0PnZR10CIsUf4yudZ9XlbtfPDWSDFERZchkGkXqGEuQxs2ivcTmHS8ti2FrPOUllNQp8s00vU1juz8B')
      //},
      //loadIntent() {
      //  postStripe({
      //    url: '/setup_intents',
      //    params: {
      //      customer: 'cus_IigNUoYJd7HLMo'
      //    }
      //  })
      //    .then(resp => {
      //      console.log("intent success", resp)
      //      this.intentToken = resp.data
      //    })
      //},
      //submitPaymentMethod() {
      //  this.stripe.confirmCardSetup(
      //    client_secret,
      //    {
      //      payment_method: {
      //        card: this.card,
      //        billing_details: {
      //          name: this.nameCard
      //        }
      //      }
      //    }
      //  ).then(function(result) {
      //    if(result.error) {
      //      console.log("submit payment error", result.error)
      //    } else {
      //      console.log("success submit payment!!", result)
      //    }
      //  })
     // }
    },
    created() {
      //const script = document.createElement('script')
      //script.src = 'https://js.stripe.com/v3/'
      //script.onload = () => this.initStripe()
      //document.body.appendChild(script)
    },
    mounted() {
      //this.loadIntent()
    }
  }

</script>